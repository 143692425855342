import React from 'react';
import { arrow1,arrow2,own,authenticate,sign_in, AsEasyAs_img } from '../assets';

const AsEasyAs = () => {
  return (
    <section section id='authentication' className='bg-[#0D1638] -translate-y-1 -skew-y-3 md:py-10'>
    <div className='flex flex-col justify-between items-center container px-6 py-10 mx-auto md:pb-10 skew-y-3'>
    <div className='justify-center w-full'>
        <h1 className='text-white font-bold text-3xl md:text-4xl lg:text-5xl'>As Easy As</h1>
     </div>     
     <div className="py-10 mx-auto w-full">
       <div className='align-middle flex flex-col rounded-lg'>          
          <img className= 'mx-auto my-auto w-full' src={AsEasyAs_img} alt="sigin" />                               
        </div>                 
     </div>
    </div>
    </section>
  )
}

export default AsEasyAs;