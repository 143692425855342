import React, { Fragment } from 'react';
import Hero from './components/Hero';
import AsEasyAs from './components/AsEasyAs';
import Assets from './components/Assets';
import Wallet from './components/Wallet';
import Benefits from './components/Benefits';

const index = () => {
  return (
    
      <div className='py-16 justify-center items-start bg-main'>        
        <Hero/>    
        <AsEasyAs/>      
        <Assets/>
        <Benefits/>
        <Wallet />
      </div>       
    
    
  )
}

export default index;