import React from "react";
import Navigation from "./Router/Navigation";
const App=()=> {
  return (
   <div className="w-full overflow-hidden">
    <div>
      <Navigation/>
    </div>

   </div>
  );
}

export default App;
