import React from "react";
import { illustration } from "../assets";
const Assets = () => {
  return (
    <section id="asset">
      <div className="flex md:flex-row flex-col justify-between items-center container px-10 md:px-0 py-10 md:py-0 mx-auto">
        <div className="flex flex-col static text-[#0D1638] md:mr-20 md:w-6/12">
          <p className="text-left text-[#0D1638] font-bold text-3xl md:text-5xl">
            Secured <br />
            Digital Assets
          </p>
          <p className="mt-1 md:mt-5  text-xl md:text-2xl font-base">
            We are building a tool kit to secure your web3 assets with
            best-practise web2 security.
          </p>
          <p className="mt-1 md:mt-5  text-xl md:text-2xl font-base">
            When you are ready to engage with your own web3 wallet, we have a
            unique and secure process for you to access your private keys.
          </p>
          <p className="mt-1 md:mt-5  text-xl md:text-2xl font-base">
            Custody options vary depending on what security you wish to achieve.
          </p>
        </div>
        <div className="mx-auto md:w-6/12">
          <img src={illustration} alt="own" />
        </div>
      </div>
    </section>
  );
};

export default Assets;
