import React from "react";
import logo from './assets/logo/logo.png';
import ezythree from './assets/logo/ezythree.png';
import { FaFacebookF,FaInstagram,FaLinkedinIn,FaTwitter,FaRedditAlien } from "react-icons/fa";

const Footerpage = () => {
  return (
    <footer className="bg-[#0D1638] font-poppins text-[#3AABD6] backdrop-blur-md shadow-lg text-sm">
    <section
      id="footer"
      className="grid grid-cols-2 gap-4 items-center container px-6 py-6 mx-auto">
      {/* LEFT */}
      <div className="">
        <div className="row flex-col py-5">
          <img
            src={logo}
            className="shadow-logo w-16"
            alt="logo"            
          />
          <img
            src={ezythree}
            className="w-24"
            alt="logo"            
          />
        </div>
        <p className="mt-1 text-xs text-white  w-1/3">
        Incididunt ut labore et dolore magne aligua.
         Ut enim ad minim veniam.
        </p>
        <div className="flex mt-0 pt-0 -mx-2 space-x-1 text-lg">
          <button className="p-2 m-1 tracking-wider text-[#3AABD6]  transition-colors duration-200 transform">
            <FaFacebookF />
          </button>
          <button className="p-2 m-1 tracking-wider text-[#3AABD6]  transition-colors duration-200 transform">
            <FaInstagram />
          </button>
          <button className="p-2 m-1 tracking-wider text-[#3AABD6] transition-colors duration-200 transform">
            <FaRedditAlien />
          </button>
          <button className="p-2 m-1 tracking-wider text-[#3AABD6]  transition-colors duration-200 transform">
            <FaLinkedinIn />
          </button>
          <button className="p-2 m-1 tracking-wider text-[#3AABD6] transition-colors duration-200 transform">
            <FaTwitter/>
          </button>
        </div>
      </div>
 
      {/* RIGHT */}
      <div className="... ">
        <div className="flex sm:flex-row flex-col justify-between text-sm">
          <div>
            <h3 className="font-bold ">About</h3>
            <a href="/" className="block mt-2 hover:underline text-white">
              Privacy Policy
            </a>
            <a href="/" className="block mt-2 hover:underline text-white">
              Legal
            </a>              
          </div>
          <div>
            <h3 className="font-bold">Blog</h3>
            <a href="/" className="block mt-2 hover:underline text-white">
              Pricing
            </a>
            <a href="/" className="block mt-2 hover:underline text-white">
              Whitepaper
            </a>              
          </div>

          <div>
            <h3 className="font-bold">Links</h3>
            <a href="/" className="block mt-2 hover:underline text-white">
              Latest News
            </a>
          </div>
        </div>
      </div>
    </section>     
  </footer>
  )
}

export default Footerpage;