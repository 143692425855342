import React from "react";
import { hero_img } from "../assets";

const bg = {
  background:
    "linear-gradient(177deg, rgba(255,255,255,1) 78%,rgba(13,22,56,1) 78.2%)",
  overflow: "hidden",
};

const Hero = () => {
  return (
    <section id="home" className="-skew-y-3 font-metropolis " style={bg}>
      <div className="flex md:flex-row flex-col justify-between items-center container px-10 py-10 md:py-0 mdpx-10 mx-auto md:pb-10 skew-y-3">
        <div className="flex flex-col static text-[#0D1638] md:mr-20">
          <p className="font-extrabold text-3xl md:text-5xl lg:text-6xl">
            Secure Web3 Wallet
          </p>
          <p className="text-[#485373] mt-1 md:mt-2  text-xl md:text-2xl font-medium">
            Easy digital ownership.
          </p>
          <p className="text-[#485373] mt-1 md:mt-2  text-xl md:text-2xl font-medium">
            Tools to enable customers to secure Web3 assets.
          </p>
          <div className="flex flex-1 mt-1 md:mt-2 mb-5">
            <button className="bg-[#1279C3] text-white sm:px-12 px-24 my-2 py-2 rounded-3xl font-medium hover:scale-110 transition-all ease-in-out duration-300">
              Contact Us
            </button>
          </div>
        </div>
        {/* two cards */}
        <div className="... ">
          <img src={hero_img} alt="hero" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
