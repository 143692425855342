import React from 'react'
import { benefit_img } from '../assets';
const Benefits = () => {
  return (
    <section id="Benefit" className='bg-[#0D1638] -skew-y-3'>
    <div className='py-10 md:py-20 skew-y-3'>
      <div className='w-11/12'>
       <h1 className='text-white font-bold text-3xl md:text-4xl lg:text-5xl text-center'>Benefits</h1>
      </div>
      <div className="justify-items-center items-center container px-6 py-10 mx-auto md:w-2/4">            
            <img
                className= 'self-center mx-auto'
                src={benefit_img}
                alt="own"
                />              
        </div>
    </div>       
    </section>
  )
}
export default Benefits;