import React from "react";
import { digital_wallet } from "../assets";

const Wallet = () => {
  return (
    <section id="wallet" className=" md:py-0">
      <div
        className="flex md:flex-row-reverse flex-col 
      md:translate-y-52
      justify-between justify-items-center items-center container mx-auto p-10 md:p-0 "
      >
        <div className="grid md:grid-cols-4">
          <div
            className="md:col-span-2 row-span-2 justify-items-end items-center 
                md:translate-y-32 shrink md:h-4/5"
          >
            <img className="w-5/6" src={digital_wallet} alt="wallet" />
          </div>
          <div className="md:col-span-2 gap-4 flex flex-col static text-[#0D1638]">
            <h1 className="text-left text-[#0D1638] font-bold text-2xl md:text-3xl lg:text-5xl">
              Secure your NFT using our web2 process
            </h1>
            <p className="mt-1 lg:mt-2 text-lg md:text-xl lg:text-2xl font-base">
              {" "}
              Built for Ethereum, other blockchains coming soon.
            </p>
            <p className="mt-1 lg:mt-2 text-lg md:text-xl lg:text-2xl font-base">
              {" "}
              Our custody arrangement ensures a breach of one system can not
              compromise your digital asset.
            </p>
            <p className="mt-1 lg:mt-2 text-lg md:text-xl lg:text-2xl font-base">
              {" "}
              When and if you decide to take control of your own wallet, we
              provide a simple, secure seed retrieval process.
            </p>
          </div>
          <div
            className="md:col-span-3 h-36  md:h-48 md:w-4/5 lg:w-4/6 px-10 md:px-20 bg-slate-50/70 backdrop-blur-md shadow-md rounded-md
                md:-translate-y-40 lg:-translate-y-56 md:-translate-x-14 mx-auto"
          >
            <div className="p-5">
              <h1 className="text-center text-[#0D1638] font-bold  text-xl md:text-3xl">Find out more.
              </h1>
              <div className="flex flex-row gap-2 justify-center">
                <input
                  type="text"
                  id="email"
                  placeholder="Your email address"
                  autocomplete="given-name"
                  className="mt-8 h-10 md:h-12 w-48 md:w-52 p-4 block rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <button className="bg-[#1279C3] text-white px-2 sm:px-6 md:px-12 py-2 rounded-md md:text-xl font-medium mt-8 hover:scale-110 transition-all ease-in-out duration-300 ">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Wallet;
