import React from 'react';
import Navbar from '../components/Navbar';
import Homepage from '../pages/home';
import Footerpage from '../components/Footerpage';

import { BrowserRouter, Routes, Route } from "react-router-dom";
const Navigation = () => {
  return (
    <BrowserRouter>
        <Navbar/>
        <Routes>    
            <Route path='/' element={<Homepage/>}/>            
        </Routes>
        <Footerpage/>
    </BrowserRouter>
  )
}

export default Navigation;